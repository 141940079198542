import React from 'react';
import { ProgressBar } from 'react-bootstrap';

export const ProgressLoadingView = ({ text = '...' }) => {
    const arr = ['Creating scope', 'Working on lesson plan', 'Describing educational usage', 'Listing needed materials', 'Formatting result', 'Creating document'];
    const [progressValue, setProgressValue] = React.useState(10);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const timeout = React.useRef(null);

    const animateBar = () => {
        timeout.current = setTimeout(() => {
            setProgressValue(prevValue => prevValue + 15);

            if (arr[currentIndex] !== 'Creating document') {
                
                setCurrentIndex(prevIndex => (prevIndex + 1) % arr.length); // Update index to next item
                animateBar();
            }
        }, 1000);
    };

    React.useEffect(() => {
        animateBar();
        return () => clearTimeout(timeout.current);
    }, [currentIndex]);

    return (
        <div className='center text-center '>
            <div className='' style={{ width: '70%', alignSelf: 'center', margin: '0 auto' }}>
                <ProgressBar now={progressValue}  striped animated
               className='max-w-[80%] center'
                style={{margin: '0 auto'}}
                />
            </div>
            <div className='p-20'
                style={{ fontSize: 30, alignSelf: 'center', fontWeight: 'bold', maxWidth: '100%', textAlign: 'center', color: 'gray' }}>
                {arr[currentIndex]}... {/* Display current item */}
            </div>
        </div>
    );
};

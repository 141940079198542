import React, { useState, useEffect, useRef } from 'react';
import './autocomplete.css';
import _ from 'lodash';

const FormAutocompleteDropdown = ({
  onGetSelectedValues = () => {},
  fillInThisTagName = null,
  _selectedTags = [],
  allowNewValues = true,
  fillInTagsOnInit = false,
  placeholder = ' Click to add materials'
}) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const suggestionsList = _selectedTags;
  const suggestionsRef = useRef(null);

  useEffect(() => {
    onGetSelectedValues(_.uniq(selectedValues));
  }, [selectedValues]);

  useEffect(() => {
    if (_selectedTags?.length && fillInTagsOnInit) {
      setSelectedTags(_selectedTags?.map(tag => tag?.name));
      setShowSuggestions(false);
      onGetSelectedValues(_selectedTags?.map(f => f?.name));
    }
    if (_selectedTags?.length && fillInThisTagName?.length) {
      const selected = [fillInThisTagName];
      setSelectedTags(selected);
      setShowSuggestions(false);
      onGetSelectedValues(selected);
    }
  }, [_selectedTags]);

  useEffect(() => {
    const filteredSuggestions = suggestionsList.filter(item =>
      item?.name?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  }, [inputValue]);

  const handleInputChange = (e) => {
    if (!allowNewValues) {
      setInputValue('');
      return;
    }
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if ((e.key === 'Enter' || e.key === 'Tab') && inputValue) {
      if (!inputValue?.trim()?.length) {
        return;
      }
      addTag(inputValue);
      addUserToList(inputValue);
      setInputValue('');
      setShowSuggestions(false);
    }
  };

  const addTag = (tagText = '') => {
    if (!selectedTags.includes(tagText)) {
      setSelectedTags([...selectedTags, tagText]);
      setInputValue('');
    }
  };

  const removeTag = (tagText = '') => {
    setSelectedTags(selectedTags?.filter(tag => tag !== tagText));
    setSelectedValues(selectedTags?.filter(tag => tag !== tagText));
  };

  const addUserToList = (email = '') => {
    let c = selectedValues?.concat() || [];
    c = c.concat(email);
    setSelectedValues(c);
  };

  const handleSuggestionClick = (suggestion = '') => {
    addUserToList(suggestion?.email || suggestion?.name);
    addTag(suggestion?.name);
    setShowSuggestions(false);
  };

  const detectClick = (event) => {
    
      const clickedId = getClickedElementId(event);
      const isOutside = !clickedId?.includes('SUGGESTION_') && !clickedId?.includes('dropDownInputFormAutoComplete')
      if (inputValue.trim() !== '' && isOutside) {
        addTag(inputValue);
        addUserToList(inputValue);
        setInputValue('');
        setShowSuggestions(false);
      }
      if(isOutside) {
        setShowSuggestions(false)
      }
    
  }
  React.useEffect(() => {

    document.addEventListener('click', detectClick)

    return () =>  document.removeEventListener('click', detectClick)

  }, [inputValue])

 
  const handleFocus = () => {
    setShowSuggestions(true);
  };

  return (
    <div className=" ">
      <div className="input-container border-gray-400 col-lg-12">
        {selectedTags.map(tag => (
          <div className="tag" key={tag}>
            <span>{tag}</span>
            <span className="remove-tag bi bi-x list-none fs-5" onClick={() => removeTag(tag)}></span>
          </div>
        ))}
        <input
          id="dropDownInputFormAutoComplete"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onClick={ handleFocus}
          
          placeholder={placeholder}
        />
      </div>
      {showSuggestions && suggestions.length > 0 && (
        <ul className="suggestions-list" ref={suggestionsRef} id='suggestionsList'>
          {suggestions.map((suggestion, index) => (
            <li id={`SUGGESTION_`+index} key={suggestion} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion?.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FormAutocompleteDropdown;

function isValidEmail(email = '') {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

const getClickedElementId = (event) => {
  return event.target.id || null;
};

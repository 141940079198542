import React from 'react'


export const About = () => {


    return(<>
        <div className='grid p-10'>
        
         <p className='h1 fs-bold text-left px-10'>   About Us</p>
        <p className='p-10 fs-5'>
            We're a small team of tech entrepreneurs with a solid track in creating software products focused on solving real life problems.
            But we're also parents and are extensively familiar with Arizona's ESA guidelines, changes, and requirements that are often get updated.
            That's why we decided to create this website to help your and us generate curriculum for our kids and avoid the risk of being rejected due to guideline changes on the ESA side.
       
            We're proud supporters of ESA as it's been of tremendous help for our kids from the start. 
            <br/>
            <br/>
            
            We enjoy the same beautiful sun and extreme summer heat like yourself in Arizona :)

            <br/><br/> Mike & Jesse
            <img src={require('./assets/images/aboutUs.jpg')} className='mt-10' style={{height: 100, borderRadius: 12}}/>
        </p>
        
       
    
        
    
    
        </div>
        </>)
}
import React from 'react';
import './mainPage.css'; // Create a corresponding CSS file for styles
import { useNavigate } from 'react-router-dom';
import { SAVE_ITEM_KEY } from './constants';

export const Home = ({onOk = () => {} }) => {

    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)
    const [imageSrc, setImageSrc] = React.useState(windowWidth >= 768 ? require('./assets/images/desktop_header.png' ): require('./assets/images/mobile.png'));
  
    React.useEffect(() => {
      const handleResize = () => {
        console.log('ksl' + window.innerWidth >= 768)
       setImageSrc(window.innerWidth >= 768 ? require('./assets/images/desktop_header.png' ): require('./assets/images/mobile.png'))
       setWindowWidth(window.innerWidth)
      }
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    React.useEffect(() => {
      sessionStorage.removeItem(SAVE_ITEM_KEY)
    }, [])

return(<div className="bg-white">
 
  <div className="relative isolate px-6 pt-14 lg:px-8">
   <MainBg/>
   <div className="w-100 width-[100]" aria-hidden="true">
         <img  src={require('./assets/images/classWallet_badge.png')} style={{margin: '0 auto', height: 80}} alt="" />
        </div>
    <div className="mx-auto max-w-2xl mt-10 mb-20">
    
      <div className="text-center">
        <h1 className="text-4xl  font-bold tracking-tight text-gray-900 sm:text-6xl leading-loose" style={{lineHeight: 1.2}}>Create Your ESA Handbook-Approved Curriculum in 10 Seconds</h1>
        <p className="mt-6 text-lg leading-8 text-gray-600">We help generate a unique <u>Arizona ESA curriculum</u> based on ESA guidelines. Don't risk getting rejected with generic AI curriculums. Get your curriculum now. </p>
        <div className="text-center center m-10 mb-0" aria-hidden="true">
         <img  src={require('./assets/images/kids.png')} alt="" />
        </div>

        <div className='row center m-10 mt-0'>
          <div className='col-md-4 border p-10 rounded text-left'>
              <div className='' style={{color:'purple', fontSize: 20, marginBottom: 10}}>Step 1</div>
              <div>Enter a few ESA-required details</div>
          </div>
          <div className='col-md-4 border p-10 rounded text-left'>
          <div className=' ' style={{color:global.slightAttentionColor, fontSize: 20, marginBottom: 10}}> Step 2</div>
            Pay $0.99 to download your curriculum in PDF format
        </div>
        <div className='col-md-4 border p-10 rounded text-left'>
          <div className=' ' style={{color:'green', fontSize: 20, marginBottom: 10}}> Step 3</div>
            
            Upload the PDF to ClassWallet
        </div>
        </div>
       
        <img  src={require('./assets/images/arrow.png')} alt="" className='text-center center' style={{margin: '0 auto', height: 90}}/>
      
        <div className="mt-10 flex items-center justify-center gap-x-6" onClick={() => window.location.href = '/new'}>
          <a href="#" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-100 p-4">
          <p className='fs-5'>
            Create Curriculum
          </p>

          </a>
        
        </div>
      </div>
    </div>

 

    <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
      <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style={{clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'}} />
    </div>
  </div>
</div>
)

return (<>
    <div className={windowWidth > 768 ? `text-center m-20` : ''}>
        <img src={(imageSrc)} alt='' className='header-image rounded' />


        <div style={{margin: '0 auto', maxWidth:'80%'}}>
            <div
              className="btn-info w-100 mt-10 px-10 py-3 cursor-pointer text-center fs-4"
              onClick={() => document.location.href = '/new'}
            >
              Create Your Curriculum
            </div>

            <div className="col-sm-12 text-center mt-10" >
              <small>
               
              </small>
            </div>
          </div>
</div>
</>
  );
}


export const MainBg = () => {
  return(  <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
    <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style={{clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'}} />
  </div>)
}
export const MainHeader = () => {

  return( <header className="absolute inset-x-0 top-0 z-50">
    <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div className="flex lg:flex-1">
        <a href="#" className="-m-1.5 p-1.5">
          <span className="sr-only">Your Company</span>
          <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />
        </a>
      </div>
      <div className="flex lg:hidden">
        <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
          <span className="sr-only">Open main menu</span>
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
      </div>
      <div className="hidden lg:flex lg:gap-x-12">
        <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Product</a>
        <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Features</a>
        <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Marketplace</a>
        <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Company</a>
      </div>
      <div className="hidden lg:flex lg:flex-1 lg:justify-end">
        <a href="#" className="text-sm font-semibold leading-6 text-gray-900">Log in <span aria-hidden="true">→</span></a>
      </div>
    </nav>
    {/* Mobile menu, show/hide based on menu open state. */}
    <div className="lg:hidden" role="dialog" aria-modal="true">
      {/* Background backdrop, show/hide based on slide-over state. */}
      <div className="fixed inset-0 z-50" />
      <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div className="flex items-center justify-between">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />
          </a>
          <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700">
            <span className="sr-only">Close menu</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6">
              <a href="#" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Product</a>
              <a href="#" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Features</a>
              <a href="#" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Marketplace</a>
              <a href="#" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Company</a>
            </div>
            <div className="py-6">
              <a href="#" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>)
}
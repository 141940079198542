import React from 'react'

import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import './alertify-custom.css'



export const FormButton = ({onClick = () => {},footNote = '', loading = false, label = '', disabled = false, isPrimary = false, isDanger = false, isFullWidth = false, superExtraClass = '', ...props }) => 
{

    let extraClass = isPrimary == true ? '' : ''
    if(isFullWidth) {
      extraClass = extraClass + ' w-100 '
    }
    if(isDanger) {
      extraClass = extraClass + ' btn-light-danger ' 
    }
    extraClass = extraClass + ' ' + superExtraClass
    
    return(  <div className="w-full flex"><a
        className={`${extraClass}  bg-indigo-600 text-white font-bold rounded hover:bg-indigo-600 text-center p-2"` }
        onClick={loading || disabled ? null :
          isDanger ? 
          () => {
            // show alert
            showMagniAlert(onClick)
            
          }
          :
          onClick}
        type="button"
        data-bs-dismiss="modal"
      >
        {loading ? "Loading..." : label}
        {loading && (
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        )}
        {
            props.children
        }
      </a>
      {
        footNote?.length ? <small className="form-text text-muted px-2">{footNote}</small> : null 
      }
      </div> 
      )
}


export const showMagniAlert = (onClick = () => {}, title = 'Confirm', message = 'Are you sure? ', ) => {
  alertify.confirm(title, message,  () => onClick(), () => {})
}
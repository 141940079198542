export const IS_TESTING_LOCALLY_WITH_NO_AI = false;

export const SUBJECT = [
  "English Language Arts",
  "Mathematics",
  "Science",
  "Social Studies",
  "Art",
  "Physical Education",
  "Fine Arts",
  "Foreign Languages",
  "Computer Science and Technology",
  "History",
  "Health",
  "Music Education",
  "Bible Studies",
  "Home Economics",
  "Economics"
];

export const GRADE = [
  "Kindergarten",
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Ninth",
  "Tenth",
  "Eleventh",
  "Twelfth",
  "College"
];

export const DISABILITY = [
  "Autism",
  "ADHD",
  "Down Syndrome",
  "Cerebral Palsy"
]

export const MATERIALS = [
  "Books",
  "Audio books",
  "Digital books",
  "Book players",
  "Coloring books",
  "Magazines",
  "Educational workbooks",
  "Planners",
  "Calendars",
  "Dictionaries",
  "Thesaurus",
  "Educational flash cards",
  "Prompt cue cards",
  "Periodic tables",
  "Writing utensils",
  "Pens",
  "Markers",
  "Pencils",
  "Highlighters",
  "Crayons",
  "Chalk",
  "Art supplies",
  "Art kits",
  "Education based art supplies",
  "School supplies",
  "Index cards",
  "Poster boards",
  "Paper",
  "Folders",
  "Binders",
  "Notebooks",
  "Staplers",
  "Scissors",
  "Tape",
  "Glue",
  "Eraser",
  "White out",
  "Sharpener",
  "Educational posters",
  "Small desk lamps",
  "Reading lamps",
  "Pencil grips",
  "Bookmarks",
  "Envelopes",
  "Rubber bands",
  "Dividers",
  "Hole punch",
  "Backpack",
  "Lunch bags",
  "Atlases",
  "Maps",
  "Globes",
  "STEM Kits",
  "STEM Items",
  "STEAM Kits",
  "STEAM Items",
  "Educational kits",
  "Manipulatives",
  "Math cubes",
  "Legos",
  "Dominoes",
  "Blocks",
  "Shapes",
  "Calculators",
  "Standard calculators",
  "Graphing calculators",
  "Scientific calculators",
  "Compass",
  "Navigation tools",
  "Protractors",
  "Rulers",
  "Educational DVDs",
  "Educational CDs",
  "Audio players",
  "Visual players",
  "Physical education items",
  "Sporting items",
  "Gym memberships",
  "Microscopes",
  "Telescopes",
  "Board games",
  "Strategy games",
  "Puzzles",
  "Timers",
  "Clocks",
  "Watches",
  "Dry erase boards",
  "Easels",
  "Bulletin board",
  "Bulletin board accessories",
  "Notebooks",
  "Pencils",
  "Pens",
  "Erasers",
  "Highlighters",
  "Markers",
  "Crayons",
  "Colored pencils",
  "Rulers",
  "Scissors",
  "Glue sticks",
  "Liquid glue",
  "Tape",
  "Index cards",
  "Binders",
  "Folders",
  "Notebook paper",
  "Construction paper",
  "Graph paper",
  "Poster boards",
  "Backpacks",
  "Lunch boxes",
  "Water bottles",
  "Pencil cases",
  "Calculator",
  "Scientific calculator",
  "Graphing calculator",
  "Stapler",
  "Staples",
  "Hole puncher",
  "Protractors",
  "Compass",
  "Whiteboard",
  "Whiteboard markers",
  "Chalk",
  "Chalkboard",
  "Hand sanitizer",
  "Disinfecting wipes",
  "Tissues",
  "Paper towels",
  "Art supplies",
  "Paints",
  "Paint brushes",
  "Sketchbooks",
  "Clay",
  "Craft sticks",
  "Glue guns",
  "Glue gun sticks",
  "Math manipulatives",
  "Educational games",
  "Flashcards",
  "Workbooks",
  "Textbooks",
  "Reading books",
  "E-books",
  "Educational software",
  "Laptop",
  "Tablet",
  "Headphones",
  "Printer",
  "Printer paper",
  "Printer ink",
  "Desk",
  "Desk chair",
  "Bookcase",
  "Lamps",
  "Desk organizers",
  "Bulletin boards",
  "Cork boards",
  "Sticky notes",
  "Desk calendar",
  "Planner",
  "Timer",
  "Math sets",
  "Microscope",
  "Science kits",
  "Globe",
  "Maps",
  "Language learning tools",
  "Musical instruments",
  "Sheet music",
  "PE equipment",
  "Sports equipment",
  "Uniforms",
  "Lab coats",
  "Safety goggles",
  "Backpack hooks",
  "Storage bins",
  "Flash drives",
  "External hard drives",
  "Drawing tablets",
  "Online course subscriptions",
  "Educational magazine subscriptions",
  "Virtual reality headsets",
  "3D printers",
  "Coding kits",
  "Robotics kits",
  "Weather stations",
  "Telescopes",
  "Magnifying glasses",
  "Binoculars",
  "Nature journals",
  "Gardening tools",
  "Seeds",
  "Soil",
  "Planters",
  "Pet supplies for class pets",
  "Aquariums",
  "Terrariums",
  "Art smocks",
  "Lab equipment",
  "Science fair display boards",
  "Book lights",
  "Standing desks",
  "Ergonomic chairs",
  "Blue light glasses",
  "White noise machines",
  "Stress balls",
  "Fidget spinners",
  "Educational posters",
  "Wall maps",
  "Curriculum subscriptions",
  "Learning apps",
  "Interactive notebooks",
  "Language translation devices",
  "Microphones",
  "Webcams",
  "Document cameras",
  "Standing desk converters",
  "Ergonomic keyboards",
  "Ergonomic mouse",
  "Reading stands",
  "Reading pillows",
  "Math flashcards",
  "Science models",
  "Anatomy models",
  "Physics kits",
  "Chemistry sets",
  "Biology kits",
  "Digital subscriptions",
  "Online tutoring services",
  "Learning management systems",
  "Virtual labs",
  "Online textbooks",
  "Smart pens",
  "Digital notebooks",
  "Online learning platforms",
  "Subscription boxes for kids",
  "Learning games",
  "Interactive whiteboards",
  "Classroom subscriptions",
  "Language immersion programs",
  "Travel expenses for educational trips",
  "Museum memberships",
  "Zoo memberships",
  "Aquarium memberships",
  "Historical site memberships",
  "Theatre tickets for educational shows",
  "Concert tickets for educational performances"
];

export const SAVE_ITEM_KEY = 'curr_d'

import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/tailwind.css";
import { FormInput } from "./components/formInput";
import { jsPDF } from "jspdf";
import ReCAPTCHA from "react-google-recaptcha";
import { MainPage } from "./Home";
import { NewCurriculum } from "./NewCurriculum";
import { About } from "./About";
import { Contact } from "./Contact";
import { Home } from "./Home";
import { AppRoutes } from "./AppRoutes";
import 'bootstrap-icons/font/bootstrap-icons.css';
import SocialProofWidget from "./components/SocialProof";
import { TopNewCurriculumButton } from "./components/TopNewCurrButton";
 
global.isDev = window.location.href.includes('localhost')
global.serverName = 'newlessonplan-prod'
global.slightAttentionColor ="rgb(252 177 101)"//  '#4ab6d9' //'#f6b63a'//
global.orange = '#f6b63a';
global.green = '#7bbf4b'

function App() {
  const [step, setStep] = React.useState(0);
  return (<>
    <div className="bg-white" style={{fontFamily:''}}>
      
      <TopNewCurriculumButton/>
      <div className="m-10 mb-2 cursor-pointer min-h-[50px]" onClick={() => window.location.href = '/'}>
      <img src={require('./assets/images/web_logo.png')} style={{maxWidth: '50%'}} />
      </div>
   {
    window.innerWidth > 800 && !window.location.href?.includes('.com/new') ?    <p className="fs-bold fs-5 mx-10 mb-2" style={{color: 'gray', }}>Arizona ESA Curriculum Generator</p> : null
   }
      </div>

   

        <AppRoutes/>
        <SocialProofWidget/>
      <Footer/>
    </>
  );
}


export default App;


const FacebookPage = () => {
  return(
    <div className="d-flex justify-content-center text-center align-items-center w-100" >
      <img src={require('./assets/images/facebook_icon.png')} style={{height: 30, marginRight: 10, alignSelf:'center'}}/>
      <a href="https://www.facebook.com/profile.php?id=61561939816406" className="text-center underline" >Follow us on Facebook</a>
  </div>
  
  )
}


const PrivacyBadge = () => {
  return(    <div className='flex flex-row items-center justify-center text-left max-w-[50%] mx-auto p-4 border rounded m-10 '
  style={{maxWidth: window.innerWidth < 800 ? '90%' : '60%'}}
  >
    {/* <i class="bi bi-shield-check fs-2"></i> */}
    <img src={require('./assets/images/badge.png')} className='h-[80px] mb-2'/>
    <p className=' px-4'>
     <span className=""> AZ Parents Owned. Proud supporters of ESA.<br/> 
      Not affiliated with SOS. We do not share or store your information.
      </span> 
    </p>
  </div>)
}

const Footer = () => {
  return(<div class="container  bg-black rounded ">
    <footer class="text-center text-white" >
   
      <div class="container bg-black">
        <section class="">
          <div class="row text-center d-flex justify-content-center pt-5">
          <div class="col-md-2">
              <h6 class="text-uppercase font-weight-bold">
                <a href="/" class="text-white">Home</a>
              </h6>
            </div>

            <div class="col-md-2">
              <h6 class="text-uppercase font-weight-bold">
                <a href="/about" class="text-white">About us</a>
              </h6>
            </div>
  
       
            <div class="col-md-2">
              <h6 class="text-uppercase font-weight-bold">
                <a href="/help" class="text-white">Help</a>
              </h6>
            </div>
  
            <div class="col-md-2">
              <h6 class="text-uppercase font-weight-bold">
                <a href="/contact" class="text-white">Contact</a>
              </h6>
            </div>

            <div class="col-md-2">
              <h6 class="text-uppercase font-weight-bold">
                <a href="/privacy-policy" class="text-white">Privacy Policy</a>
              </h6>
            </div>
          </div>
        </section>
  
        <hr class="my-5" />
  
        <section class="mb-5">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-8">
              <p>
              Note: we cannot guarantee approval. However, we'll create a
                curriculum that adheres to the Arizona ESA guidelines and that
                we used for our kids to get approved.
              </p>
            </div>
            <PrivacyBadge/>
            <div class="col-lg-8 mt-4 ">
              <p className="text-success">
            
              </p>
            </div>
          </div>
        </section>
  
      
      </div>
      <FacebookPage/>
      <div class="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
        © 2024 Copyright 
        <a class="text-white px-1" href="https://mdbootstrap.com/">NewLessonPlan.com</a>
      </div>
    </footer>
  </div>
  )
}
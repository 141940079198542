import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/tailwind.css";
import { FormInput } from "./components/formInput";
import { jsPDF } from "jspdf";
import ReCAPTCHA from "react-google-recaptcha";
import { MainBg, MainPage } from "./Home";
import { FormTwoColumnView } from "./components/formTwoColumnView";
import { FormSelect } from "./components/formSelect";
import { CLASS, DISABILITY, GRADE, IS_TESTING_LOCALLY_WITH_NO_AI, MATERIALS, SAVE_ITEM_KEY, SUBJECT } from "./constants";
import FormAutocompleteDropdown from "./components/formAutoComplete";
import DataModel from "./DataModel";
import { FormButton } from "./components/formButton";
import alertify from "alertifyjs";
import { Docky } from "./Docky";
import { FormCheckbox } from "./components/formCheckbox";
import { FormLabel } from "./components/FormLabel";
import SwitchControl from "./components/SwitchControl";



export const NewCurriculum = () => {
  const captchaRef = React.useRef(null);
  const [name, setName] = React.useState('')
  const [applicationNumber, setsetApplicationNumber] = React.useState('')
  const [subject, setSubject] = React.useState('')
  const [materials, setMaterials] = React.useState([])
  const [grade, setGrade] = React.useState('')
  const [_class, _setClass] = React.useState('')
  const [loading, setLoading] = React.useState(false);
  const [didResolveCaptcha, setDidResolveCaptcha] = React.useState(false)
  const [getAsJson, setGetAsJson] = React.useState(false)
  const [hasDisability, setHasDisability] = React.useState(false)
  const [disability, setDisability] = React.useState('');
  const [email, setEmail] = React.useState('support@newLessonPlan.com')

  return (<>
  <div className="relative isolate ">
  
    <div className="mt-20">
      
      <div className="text-center">
      <div className="fs-1 fw-bold "
      
      >Create Your Curriculum
        
      </div>
      <h3 style={{maxWidth:'80%', alignSelf:'center', margin: '0 auto', color: 'gray'}}>
        
        </h3>
      </div>
   
      <div className="m-20">

    

      {getAsJson ? null :   <div className="row">
        <div className="col-lg-6">
        <FormInput label="Student Full Name" autoFocus
        placeholder="" text={name} onTextChange={t => setName(t)}  />
        </div>
        <div className="col-lg-6">
        <FormInput label="ESA Application Number"  text={applicationNumber} onTextChange={t => setsetApplicationNumber(t)}/>
        </div>
        </div>}
        <div className="row  mb-10 cursor-pointer text-center"
    
    >
        <div className="col-md-12 flex rounded"
         
        >
        <FormCheckbox label="Click if you don't want to provide student name/application number " 
        value={getAsJson} 
         onChange={v => {setGetAsJson(v)}}/>
          
           <div className=" underline text-left"
           style={{textDecorationColor: 'gray'}}
         onClick={() => {
          alertify.alert('Info', `
            if you provide name/application Id, you will get a PDF that you simply drag and drop onto ClassWallet.
            But if you prefer to add student name and application number yourself, we will give you a Word document that you can edit, save, and then drop onto ClassWallet.`)
         }}
         >
          <span className="text-gray-500" style={{fontSize: 14, color: 'gray'}}>
          What's the difference?
          </span>
          </div>
        </div>
       
         
      </div>


      
        {/* <div className="row">
        <div className="col-lg-6">
        <FormInput label="Subject"  text={subject} onTextChange={t => setSubject(t)}/>
        </div>
        <div className="col-lg-6">
        
        </div>
        </div> */}


        <div className="row">
        <div className="col-lg-6">
        <FormSelect _options={SUBJECT.map(f => ({label: f, value:f}))} label="Subject" onSelect={e => setSubject(e)}   />
        </div>
        <div className="col-lg-6">
        <FormSelect _options={GRADE.map(f => ({label: f, value:f}))} label="Grade" onSelect={e => setGrade(e)}  placeholder="Select grade..."  />
        </div>
        </div>

        <div className=" mb-12">
        <div className="row mt-1 mb-4">
          <div className="row">
          <SwitchControl checked={hasDisability} onToggle={() => setHasDisability(!hasDisability)}/>
          </div>
            </div>
      {!hasDisability ? null : <div className="col-lg-12">  <FormAutocompleteDropdown allowNewValues  _selectedTags={DISABILITY?.map(f=>({name: f}))} 
        placeholder={disability?.length ? '' :  "Click to add a disability or type your own and press 'Enter'"}
        onGetSelectedValues={e => setDisability(e)}
        /></div>}
    </div>
       
        <div className="row mb-20">
      <div className="form-label fw-bold">Materials</div>
      <FormAutocompleteDropdown allowNewValues  _selectedTags={MATERIALS?.map(f=>({name: f}))} 
        
        onGetSelectedValues={e => setMaterials(e)}
        />
    <small className="color-[gray]" style={{color: 'gray'}}>Select from the list or type your own and press 'Enter'.
    <span className="color-[gray]" style={{color: 'orange', marginLeft: 10 }}>Note: For books, use: Book - Title (e.g. Book - Tale of Two Cities) to avoid rejection.</span>

    </small>
   
    </div>

        {/* <ReCAPTCHA
          ref={captchaRef}
          sitekey={`6LccdQsqAAAAAPjYyZna8d_l666BsSYLblpoVGhP`}
          onChange={() => {
            //enable main button
            setDidResolveCaptcha(true)
          }}
        /> */}


        {/* <div className="col-lg-6 ">
        <FormInput label="Email to send the curriculum to" 
        text={email} onTextChange={t => setEmail(t)}/>
        </div> */}


            <FormButton
            loading={loading}
            label="Pay $0.99 to Download Curriculum" isFullWidth superExtraClass="p-3 bg-indigo-600"
             onClick={async () => {

          
              if(((!name?.length || !applicationNumber?.length) && !getAsJson) || !subject?.length || !grade?.length || !materials?.length) {
                alertify.alert('Missing Info', 'All fields are required. Please fill out missing fields')
                return;
              }

              if(hasDisability && !disability?.length) {
                alertify.alert('Missing Info', 'Enter type of disability or toggle disability off to continue.')
                return;
              }

              if(!email?.length || !validateEmail(email)) {
                alertify.alert('Invalid Email', 'Please enter a valid email to send the curriculum to.')
                return;
              }

              // if(!didResolveCaptcha) {
              //   alertify.alert('Captcha', `Please check "I'm not a robot box" to continue`);
              //   return;
              // }
              setLoading(true);
              const data = {studentName: name, applicationNumber: applicationNumber, subject: subject, grade: grade, materials: materials?.join(', '), disability: hasDisability && disability?.length ? disability?.join(', ') : '', getAsJson: getAsJson, email: email};
              //save data 
              sessionStorage.setItem(SAVE_ITEM_KEY, JSON.stringify(data))
              if(IS_TESTING_LOCALLY_WITH_NO_AI) {
              window.location.href = '/success'
              return;
              }
              //redirect to payment
              window.location.href = global.isDev ?  'https://buy.stripe.com/test_eVa2bPfAYgvFe5y6oo' :
              'https://buy.stripe.com/4gwcNG1iq1Ot4SI8ww'
            
            
            }}
            />

        <div className="text-center mt-10">
        <small>Once you click the above button, you'll be redirected to pay $0.99. Once payment is complete your curriculum will download to your device.</small>
        </div>
      </div>
    </div>
    </div>
    </>
  );
};


function validateEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}

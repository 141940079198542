/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import App from './App'
import { Home } from './Home'
import { About } from './About'
import { Contact } from './Contact'
import { NewCurriculum } from './NewCurriculum'
import { Help } from './help'
import { Success } from './Success'
import { PrivacyPolicy } from './PrivacyPolicy'


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {BASE_URL} = '/'

const AppRoutes = () => {
  


  return (
    <BrowserRouter basename={BASE_URL}>
      <Routes>
                <Route element={<App />} />
              <Route path='/' element={<Home />} />         
              <Route path='about' element={<About />} />    
              <Route path='help' element={<Help />} />         
              <Route path='contact' element={<Contact />} />
              <Route path='success' element={<Success />} />
              <Route path='new' element={<NewCurriculum />} />    
              <Route path='privacy-policy' element={<PrivacyPolicy />} />
              <Route path='*' element={<Home />} />      
              
    
       
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
